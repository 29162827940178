// eslint-disable-next-line no-unused-vars
import { h } from "preact"
import style from './SuPlusPaySummary.sass';
import Documents from '../Utils/Documents.json';
import logoSuPlusPay from '../../assets/original-icons/su-plus-pay-logo.svg';
import PropTypes from 'prop-types';

const SuPlusPaySummary = (props) => {
  const { transaction } = props;
  const { merchant, paymentMethod, status, statusMessage } = transaction;

  const statusText = {
    PENDING: 'Pendiente',
    ERROR: 'Fallida',
    DECLINED: 'Rechazada',
    APPROVED: 'Aprobada',
  }; 
  
  const suPlusPaySummaryStatus = statusText[status];
  const statusClass = status.toLowerCase();
  return (
    <div className={style.suPlusPayPaymentMethodWrapper}>
      <div className={style.paidWith}>Pago efectuado con</div>
      <img src={logoSuPlusPay} className={style.suPlusPayLogo} alt='SU+Pay' />
      <table className={style.trxInfo} cellPadding="5">
        <tbody>
          <tr>
            <td
              className={`${style.subContent} ${style[statusClass]}`}
              colSpan="2">
              SU+ Pay
            </td>
          </tr>
          <tr>
            <td className={style.prop}>Pago efectuado a:</td>
            <td>
              {merchant.name} - ({merchant.legalName} {merchant.legalIdType}{' '}
              {merchant.legalId})
            </td>
          </tr>
          <tr>
            <td className={style.prop}>Estado de la transacción:</td>
            <td>{suPlusPaySummaryStatus}</td>
          </tr>
          {statusMessage && (
            <tr>
              <td className={style.prop}>Mensaje del operador:</td>
              <td>{statusMessage}</td>
            </tr>
          )}
          <tr>
            <td className={style.prop}>Tipo de documento pagador:</td>
            <td>{Documents[paymentMethod.userLegalIdType]}</td>
          </tr>
          <tr>
            <td className={style.prop}>Documento pagador:</td>
            <td>{paymentMethod.userLegalId}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

SuPlusPaySummary.propTypes = {
  transaction: PropTypes.shape({
    merchant: PropTypes.shape({
      name: PropTypes.string,
      legalName: PropTypes.string,
      legalIdType: PropTypes.string,
      legalId: PropTypes.string,
      userLegalIdType: PropTypes.string,
    }),
    paymentMethod: PropTypes.shape({
      userLegalIdType: PropTypes.string,
      userLegalId: PropTypes.string,      
    }),
    status: PropTypes.string,
    statusMessage: PropTypes.string,
  }),
};

export default SuPlusPaySummary;
