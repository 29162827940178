// eslint-disable-next-line no-unused-vars
import { h } from "preact"
import style from './DaviplataSummary.sass';
import Documents from '../Utils/Documents.json';
import logoDaviplata from '../../assets/original-icons/daviplata-logo.svg';
import PropTypes from 'prop-types';

const DaviplataSummary = (props) => {
  const { transaction } = props;
  const { merchant, paymentMethod, status } = transaction;

  const statusLabel = {
    PENDING: 'Pendiente',
    ERROR: 'Fallida',
    DECLINED: 'Rechazada',
    APPROVED: 'Aprobada',
  }; 
  
  const daviplataSummarytatus = statusLabel[status];
  const statusClass = status.toLowerCase();
  return (
    <div className={style.daviplataPaymentMethodWrapper}>
      <div className={style.paidWith}>Pago efectuado con</div>
      <img src={logoDaviplata} className={style.theDaviplataLogo} alt='Daviplata' />
      <table className={style.trxInfo} cellPadding="5">
        <tbody>
          <tr>
            <td
              className={`${style.subContent} ${style[statusClass]}`}
              colSpan="2">
              Daviplata
            </td>
          </tr>
          <tr>
            <td className={style.prop}>Pago efectuado a:</td>
            <td>
              {merchant.name} - ({merchant.legalName} {merchant.legalIdType}{' '}
              {merchant.legalId})
            </td>
          </tr>
          <tr>
            <td className={style.prop}>Estado de la transacción:</td>
            <td>{daviplataSummarytatus}</td>
          </tr>
          <tr>
            <td className={style.prop}>Tipo de documento pagador:</td>
            <td>{Documents[paymentMethod.userLegalIdType]}</td>
          </tr>
          <tr>
            <td className={style.prop}>Documento pagador:</td>
            <td>{paymentMethod.userLegalId}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

DaviplataSummary.propTypes = {
  transaction: PropTypes.shape({
    merchant: PropTypes.shape({
      name: PropTypes.string,
      legalName: PropTypes.string,
      legalIdType: PropTypes.string,
      legalId: PropTypes.string,
      userLegalIdType: PropTypes.string,

    }),
    paymentMethod: PropTypes.shape({
      userLegalIdType: PropTypes.string,
      userLegalId: PropTypes.string,      
    }),
    status: PropTypes.string
  }),
};

export default DaviplataSummary;
