// eslint-disable-next-line no-unused-vars
import { h, Component } from "preact";
import style from "./RetryTime.sass";
import countdown from "../../assets/countdown.png";

class RetryTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.calculateTimeRemaining(),
      colorTimer: "timerGreen",
      colorRanges: { green: 90000, orange: 100 }
    };
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateTimeRemaining() {
    const { store } = this.props;
    const { transaction } = store.$state;
    const { expirationTimeRetry } = transaction;
    const endDate = new Date(expirationTimeRetry).getTime();
    const currentTime = new Date().getTime();
    const difference = endDate - currentTime;
    if (difference <= 0) {
      this.props.onCountdownFinished();
      return 0;
    }

    return difference;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.getColorTimer(this.state.time);
      this.setState({
        time: this.calculateTimeRemaining(),
      });
    }, 1000);
  }

  getColorTimer(valueTime) {
    const { colorRanges } = this.state;

    let newColorTimer = "timerRed";

    if (valueTime >= colorRanges["green"]) {
      newColorTimer = "timerGreen";
    }

    if (
      valueTime < colorRanges["green"] &&
      valueTime >= colorRanges["orange"]
    ) {
      newColorTimer = "timerOrange";
    }

    if (valueTime < colorRanges["orange"]) {
      newColorTimer = "timerRed";
    }

    newColorTimer && this.setState({ colorTimer: newColorTimer });
  }

  renderTimer() {
    const { time } = this.state;
    const secs = Math.floor(Math.abs(time) / 1000);
    const mins = Math.floor(secs / 60);

    return [
      { key: "Minutos", value: mins },
      { key: "Segundos", value: secs % 60 },
    ];
  }

  render() {
    const { colorTimer, time } = this.state;
    return (colorTimer && time > 0) ? (
      <div className={`${style.timer} ${style.hideForPrinting}`}>
        <div className={`${style.timerBox} ${style[colorTimer]}`}>
          <div className={style.contentTimerBox}>
            <div className={style.first}>
              <img src={countdown} />
              <span className={style.description}>
                {"Este nuevo intento de pago vence en:"}
              </span>
            </div>
            <div className={style.second}>
              {this.renderTimer().map((item) => (
                <div className={style.time} key={item.key}>
                  <span className={style.timeValue}>{item.value}</span>
                  <span className={style.timeText}>{item.key}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

export default RetryTime;
