import style from "./Header.sass";

export default function Header(props) {
  const { $state } = this.props.store
  const { transaction } = $state
  const { merchant } = transaction

  if (merchant) {
    return (
      <div className={style.header}>
        <div className={style.logoWrapper}>
          { merchant.logoUrl && (
            <div
              style={{ backgroundImage: `url(${ merchant.logoUrl })` }}
              className={`${style.logo} force-printable`}>
            </div>
          )}
          <div className={style.merchant}><span>Pago a</span>&nbsp;<strong className={style.textBreak}>{ merchant.name }</strong></div>
        </div>
      </div>
    )
  }
}
