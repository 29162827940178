export default {
  get ( key ) {
    let ret = window.localStorage.getItem(key)
    return ( ret === null )? null : JSON.parse(ret)
  },
  set ( key, val ) {
    return window.localStorage.setItem(key, JSON.stringify(val))
  },
  getString ( key ) {
    return window.localStorage.getItem(key)
  },
  setString ( key, val ) {
    return window.localStorage.setItem(key, val)
  },
  remove ( key ) {
    return window.localStorage.removeItem(key)
  },
  clear ( ) {
    return window.localStorage.clear()
  }
}
