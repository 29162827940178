// Quick way to add thousand separators
// taken from: https://stackoverflow.com/a/2901298/2164332
//
// Later we can look at using Number.prototype.toLocaleString(), but it doesn't support IE10
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString

export function formatPrice(number) {
  const array = number.toString().split("")
  const lastDigit = array[array.length - 1]
  const penultimateDigit = array[array.length - 2] == undefined ? 0 : array[array.length - 2]
  const digits = `${penultimateDigit}${lastDigit}`
  const priceWithoutCents = Number(number.toString().slice(0, -2));
  const price = parseFloat(`${priceWithoutCents}.${digits}`)
  let minimumFractionDigits = 0

  if ( digits !== '00' ) {
    minimumFractionDigits = 2
  }

  return `$ ${price.toFixed(minimumFractionDigits).replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
}

export function formatCurrency(value) {
  return value.trim().toUpperCase();
}
