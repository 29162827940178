export function getURLSearchParams(queryString) {
  return (
    queryString
      .substring(1) // remove "?"
      .split("&") // split key/value pairs
      // populate result object
      .reduce((result, currentValue) => {
        const pairArray = currentValue.split("=");
        result[pairArray[0]] = pairArray[1];
        return result;
      }, {})
  );
}

export function getMerchantErrors ( merchant, currency ) {
  const errors = []
  
  // Checks if payment method is configured correctly
  const methods = merchant.acceptedPaymentMethods

  if (!methods || methods.length === 0) {
    errors.push('No hay métodos de pago configurados para este comercio. Habilita uno o más métodos en el panel de comercios.')
  }

  // Check if accepted currencies are configured correctly
  const acceptedCurrencies = merchant.acceptedCurrencies
  if ( !acceptedCurrencies || acceptedCurrencies.length === 0 ) {
    errors.push(`No hay monedas aceptadas configuradas para este comercio. Configura una o más monedas aceptadas en el panel de comercios.`)
  } else if ( acceptedCurrencies && acceptedCurrencies.length > 0 &&
    !acceptedCurrencies.some(ac => ac.toLowerCase() === currency.toLowerCase())
  ) {
    errors.push(`La moneda ${currency} usada en esta transacción, no es aceptada para este comercio. Monedas aceptadas: ${acceptedCurrencies.join(', ')}. Configura las monedas aceptadas en tu panel de comercios.`)
  }

  return errors
}
