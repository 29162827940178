import style from './SecureTransferFooter.sass'
import productLogo from '../../assets/product_logo.svg'
import financialInstitutionLogo from '../../assets/financial-institution.png'

export default function SecureTransferFooter ( props ) {
  return (
    <div className={style.container}>
      <div>
        <img className={ `${style.financialInstitution} ${style.desktop}` } src={financialInstitutionLogo} />
        <div className={style.text}>Pagos seguros por</div>
        <img className={style.logo} src={productLogo} alt={'wompi'} />
      </div>
    </div>
  );
}