import { h, Component } from "preact"
import { Router } from "preact-router"
import Header from "../Header/Header"
import PricePanel from "../PricePanel/PricePanel"
import Confirmation from "../Confirmation/Confirmation"
import Hello from "../Hello/Hello"
import TestLabel from "../TestLabel/TestLabel"
import SecureTransferFooter from "../SecureTransferFooter/SecureTransferFooter"
import style from "./App.sass"
import paymentsByLogo from "../SecureTransferFooter/nequi-logo-color.svg"
import Checker from '../Checker/Checker'

// Global state management
import InitialState from '../../store/State.js'
import Store from '../../store/Store.js'

if ( module.hot ) {
  require("preact/debug")
}

export default class App extends Component {
  constructor() {
    super()
    let store = Store.create(InitialState, this)
    store.$actions.initApp()
    this.state = {
      store,
    }
  }

  componentDidMount() {
  }

  render() {
    const { store } = this.state
    const { $state } = store
    const isSandbox = store.$actions.isSandbox()

    // <Checker store={store} path="/check" />
    return (
      <div className={style.appWrapper}>
        { store.$actions.isSandbox() &&
          <div className={style.testLabelWrapper}><TestLabel /></div>
        }
        <div id="app" className={`${style.app} ${isSandbox ? style.isSandbox : ''}`}>
          <div className={style.routeWrapper}>
            <Router>
              <Confirmation store={store} path="/check" />
              <Hello store={store} default />
            </Router>
          </div>
        </div>
        <SecureTransferFooter store={store} />
      </div>
    )
  }
}
