
export default {
  transactionGet ( store, transactionId, successCb, errorCb ) {
    store.$actions.setTransaction(null)
    store.$operations.getTransactionByNumber(transactionId)
      .then( res => {
        const transaction = Object.assign({}, store.$state.transaction, res.data)
        store.$actions.setPublicKey(transaction?.merchant?.publicKey)
        store.$operations.getTransactionRetry(transaction.id).then( res => {
          let retryTransactionInfo = res?.data;
          const canRetryTransaction = {...transaction, ...{canRetry: retryTransactionInfo.canRetry, signatureIntegrity: retryTransactionInfo.signatureIntegrity}}
          store.$actions.setTransaction(canRetryTransaction);
          successCb(canRetryTransaction)
        }).catch(errorResponse => {
          store.$actions.setTransaction(transaction)
          errorCb(errorResponse)
        })
      }).catch(errorResponse => {
        errorCb(errorResponse)
      })
  },

  checkTransactionForFinalStatus ( store, transactionId, nextStepCb, failedCb, maxCheckingTimeSecs = 300, longPollingWaitingTime = 1500 ) {
    const loader = 'checkingTransaction'
    store.$actions.setLoader(loader, true)
    let transactionCreationLocalDate = new Date()
    let longPollingAttempts = 0

    let getSucceeded = ( transaction ) => {
      let now = new Date()
      let msTimeDiff = now.getTime() - transactionCreationLocalDate.getTime()
      // Max secs check and then set `status` to `PENDING`? and notify user, he'll receive the result of the transaction in an email
      if ( msTimeDiff > maxCheckingTimeSecs * 1000 ) {
        store.$actions.setLoader(loader, false)
        nextStepCb(transaction)
      } else if ( transaction.status === 'PENDING' ) {
        setTimeout( () => {
          checkIt()
        }, longPollingWaitingTime)
      } else {
        store.$actions.setLoader(loader, false)
        nextStepCb(transaction)
      }
    }

    let getFailed = ( errorResponse ) => {
      let now = new Date()
      let msTimeDiff = now.getTime() - transactionCreationLocalDate.getTime()
      // Max 10 secs check and then set `status` to `PENDING` and notify user, he'll receive the result of the transaction in an email
      if ( errorResponse.status === 404 ) {
        store.$actions.setLoader(loader, false)
        failedCb('La transacción solicitada no existe.')
      } else if ( msTimeDiff > 10000 ) {
        store.$actions.setLoader(loader, false)
        nextStepCb(store.$state.transaction)
      } else {
        longPollingAttempts += 1
        setTimeout( () => {
          checkIt()
        }, longPollingWaitingTime)
      }
    }

    let checkIt = function ( ) {
      store.$actions.transactionGet(transactionId, getSucceeded, getFailed)
    }
    
    checkIt()
  },

}