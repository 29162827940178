import React from 'react'
import { formatPrice } from "../Formatter/Formatter"
import style from './PCOLSummaryBox.sass'
import logopco from '../../assets/original-icons/pco-logo-summary.svg'

const PCOLSummaryBox = (props) => {

    const {
        redeemedPoints,
        moneyAmount,
        accumulatedPoints
    } = props

    const formattedAmount = typeof moneyAmount === 'number' ? formatPrice(moneyAmount) : ''
    let paymentType = null

    if (redeemedPoints && moneyAmount)
        paymentType = 'Puntos + dinero'
    else if (redeemedPoints && !moneyAmount)
        paymentType = 'Puntos'
    else if (!redeemedPoints && moneyAmount)
        paymentType = 'Dinero'

    return (
        <div className={style.summaryBoxContainer}>
            <div className={style.summaryBoxContent}>
                <span className={style.boxTitle}>
                    <strong>Forma de pago:&nbsp;</strong>{paymentType}
                </span>
                <div className={style.summaryPayment}>
                    {
                        redeemedPoints ?
                            <div className={style.pointsSection}>
                                <span className={style.pointsAmount}>
                                    {redeemedPoints}
                                </span>
                                <span className={style.pointsAmount}>Puntos</span>
                            </div> : ''
                    }
                    {
                        redeemedPoints && moneyAmount ?
                            <span className={style.plusSeparator}>+</span> : ''
                    }
                    {
                        moneyAmount ?
                            <div className={style.moneySection}>
                                <span className={style.moneyAmount}>
                                    {formattedAmount}
                                </span>
                                <span className={style.moneyAmount}>Dinero</span>
                            </div>
                            : ''
                    }
                </div>
                {
                    accumulatedPoints ?
                        <div className={style.accumulatedPointsSection}>
                            <img src={logopco} alt="" className={style.accumulatedPointsImg} />
                            <div className={style.accumulatedPointsText}>
                                <span><strong>¡Acumulaste Puntos por tu compra!</strong></span>
                                <span>En las próximas horas se cargarán los puntos en tu cuenta.</span>
                            </div>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
}

export default PCOLSummaryBox

