export function hotjarDispatch({ type, action }) {
    try {
      window.hj = window.hj || [];
  
      if(typeof window.hj === 'function') {
        window.hj(type, action)
      }
    } catch (error) {
      console.log(error)
    }
  }