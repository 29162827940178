import { h, Component } from "preact"
import { formatPrice, formatCurrency } from "../Formatter/Formatter"
import style from "./Confirmation.sass"
import { getURLSearchParams } from '../Utils/Utils'
import LoaderSpinner from '../Loaders/LoaderSpinner'
import logoPSE from '../../assets/original-icons/pse.png'
import logoBANCOLOMBIA_TRANSFER from '../../assets/original-icons/bancolombia-transfer.svg'
import Header from "../Header/Header"
import PricePanel from "../PricePanel/PricePanel"
import PCOLSummaryBox from "../PCOLSummaryBox/PCOLSummaryBox"
import RetryTime from "../RetryTime/RetryTime"
import BNPLSummary from '../BNPLSummary/BNPLSummary'
import DaviplataSummary from '../DaviplataSummary/DaviplataSummary'
import SuPlusPaySummary from '../SuPlusPaySummary/SuPlusPaySummary'
import logoBnpl from '../../assets/original-icons/bancolombia-bnpl.svg'
import logoSuPlusPay from '../../assets/original-icons/su-plus-pay-logo.svg';
import logoDaviplata from '../../assets/original-icons/daviplata-logo.svg';
import retryIconTitle from '../../assets/retryIcon.png'
import PropTypes from 'prop-types';
import { hotjarDispatch } from '../../utils/helpers'
import Documents from '../Utils/Documents.json'

const paymentMethodLocales = {
  CARD: 'Tarjetas',
  NEQUI: 'Nequi',
  PSE: 'PSE',
  BANCOLOMBIA_TRANSFER: 'Transferencia Bancolombia',
  BANCOLOMBIA_QR: 'QR Bancolombia',
  BANCOLOMBIA_COLLECT: 'Pago en efectivo',
  BANCOLOMBIA: 'TokenBox Bancolombia',
  BANCOLOMBIA_BNPL: 'Compra y Paga Después Bancolombia',
  PCOL: 'Puntos Colombia',
  DAVIPLATA: 'Daviplata',
  SU_PLUS: 'SU+ Pay',
}

const paymentMethodsLogo = {
  PSE: {logo: logoPSE, style: 'theLogo'},
  BANCOLOMBIA_BNPL: {logo: logoBnpl, style: 'theBnplBancolombiaLogo'},
  BANCOLOMBIA_TRANSFER: {logo: logoBANCOLOMBIA_TRANSFER, style: 'theBancolombiaLogo'},
  SU_PLUS: {logo: logoSuPlusPay, style: 'theSuPlusPayLogo'},
  DAVIPLATA: {logo: logoDaviplata, style: 'theDaviplataLogo'},
}

const pseStatusDict = {
  'PENDING': 'Pendiente',
  'ERROR': 'Fallida',
  'DECLINED': 'Rechazada',
  'APPROVED': 'Aprobada',
}

const configRetryGlobal = {
  isRetryEnabled: false,
  paymentMethodsAllowed: [
    "SU_PLUS",
    "DAVIPLATA",
    "BANCOLOMBIA_BNPL",
    "BANK_ACCOUNT",
    "CARD_TTP",
    "BANCOLOMBIA_QR",
    "BANCOLOMBIA",
    "PSE",
    "BANCOLOMBIA_TRANSFER",
    "NEQUI",
    "CARD"
  ],
  retryTimeoutInSeconds: "180"
}

class Confirmation extends Component {
  constructor() {
    super()
    this.bodyRetryRef = null
    this.state = {
      error: null,
      loaded: false,
      id: null,
      env: null,
      countdownFinished: false,
      isBodyRetryEnabled: false
    }
  }

  componentDidMount() {
    const { store } = this.props
    const urlParams = getURLSearchParams(window.location.search)
    let id = urlParams.id
    let env = urlParams.env
    this.setState({ id, env })
    store.$actions.setEnv(env)
    if (id) {
      store.$actions.checkTransactionForFinalStatus(id, this.finishedChecking, this.failedChecking, 6)
    } else {
      this.setState({ error: 'No hay un ID para verificar la transacción' })
    }
  }

  componentDidUpdate() { }

  failedChecking = (error) => {
    this.setState({ error: error })
  }

  handleCountdownFinished() {
    this.setState({ countdownFinished: true });
  }

  finishedChecking = async (transaction) => {
    const { store } = this.props
    if (transaction.paymentMethod.extra && transaction.paymentMethod.extra.parentTransactionId) {
      store.$operations.getTransactionByNumber(transaction.paymentMethod.extra.parentTransactionId).then(res => {
        const transactionWithParent = Object.assign({}, store.$state.transaction, { parentTransaction: res.data })
        store.$actions.setTransaction(transactionWithParent)
        this.setState({ loaded: true })
      }).catch(error => console.error(error.json.error.reason))
    } else {
      if (this.validateRetry(transaction)) {
        let configRetry;
        try {
          const res = await store.$operations.getRetryTransactionConfig(transaction?.merchant?.publicKey);
          configRetry = res.data;
        } catch (error) {
          configRetry = configRetryGlobal;
        }

        let nTransaction = {...transaction, ...{expirationTimeRetry: new Date(new Date(transaction.finalizedAt).getTime() + (configRetry?.retryTimeoutInSeconds * 1000)).toISOString()}};
        store.$actions.setTransaction(nTransaction)
      }

      this.setState({ loaded: true })
    }
    // route('summary', true)
  }

  print = () => {
    window.print()
  }

  renderLoading = () => {
    const { store } = this.props
    const { error } = this.state
    const isSandbox = store.$actions.isSandbox()

    return (
      <div className={style.contentSection} >
        { error === null &&
          <div className={style.contentContainer} style="text-align: center;">
            <div className={`${style.viewTitle} ${isSandbox ? style.isSandbox : ''}`}>
              <span className={style.title}>Confirmando tu pago</span>
            </div>
            <div className="loader-spinner">
              <LoaderSpinner/>
            </div>
            <p className="long-polling-text">
              Espera unos momentos mientras <strong>procesamos tu transacción</strong>.
            </p>
          </div>
        }
        { typeof error === 'string' &&
        <div className={style.contentContainer}>
          <div className={`${style.viewTitle} ${isSandbox ? style.isSandbox : ''}`}>
            <div className={style.iconWrapper}>
              <div className={`${style.icon} ${style.error}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 56 56"
                  >
                    <circle
                      cx="28.0006"
                      cy="27.9999"
                      r="22.4"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.0003 4.66669C15.1137 4.66669 4.66699 15.1134 4.66699 28C4.66699 40.8867 15.1137 51.3334 28.0003 51.3334C40.887
            51.3334 51.3337 40.8867 51.3337 28C51.3337 15.1134 40.887 4.66669 28.0003 4.66669ZM39.667 17.7788L38.2215 16.3334L28.0003 26.5545L17.7792
            16.3334L16.3337 17.7788L26.5548 28L16.3337 38.2212L17.7792 39.6667L28.0003 29.4455L38.2215 39.6667L39.667 38.2212L29.4458 28L39.667 17.7788Z"
                    />
                  </svg>
                </div>
            </div>
            <span className={style.title}>Ocurrió un error</span>
          </div>
          <p><strong>{ error }</strong></p>
        </div>
        }
      </div>
    )
  }

  renderInfoCurrentView = (transaction) => {
    const { status, paymentMethod } = transaction
    const { store } = this.props
    const isSandbox = store.$actions.isSandbox()
    let localeStatus = ''

    if ( status === 'APPROVED' ) {
      localeStatus = 'Transacción aprobada'
    } else if ( status === 'DECLINED' ) {
      localeStatus = 'Transacción declinada'
    } else if ( status === 'ERROR' ) {
      localeStatus = 'Transacción con error'
    } else if ( status === 'VOIDED' ) {
      localeStatus = 'Transacción anulada'
    } else if (status === 'PENDING' && (paymentMethod.type === 'BANCOLOMBIA_BNPL')) {
      localeStatus = 'Transacción en verificación'
    } else if ( status === 'PENDING' ) {
      localeStatus = 'Transacción pendiente'
    }
    
    localeStatus += ` (${paymentMethodLocales[paymentMethod.type]})`

    return (
      <div key={localeStatus} className={`${style.viewTitle} ${isSandbox ? style.isSandbox : ''} ${style.centered}`}>
        <div className={style.iconWrapper}>
          {
            status === 'APPROVED' && (
            <div className={`${style.icon} ${style.approved}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 56 56"
              >
                <circle
                  cx="27.9989"
                  cy="28.0003"
                  r="19.6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.9998 4.66669C15.1132 4.66669 4.6665 15.1134 4.6665 28C4.6665 40.8867 15.1132 51.3334 27.9998 51.3334C40.8865
      51.3334 51.3332 40.8867 51.3332 28C51.3332 15.1134 40.8865 4.66669 27.9998 4.66669ZM42.8935 19.4167L41.1062 17.9167L24.2968
      37.9424L14.8229 28.5124L13.1768 30.1662L24.4528 41.3896L42.8935 19.4167Z"
                />
              </svg>
            </div>
            )
          }
          {
            (status === 'ERROR' || status === 'DECLINED') && (
              <div className={`${style.icon} ${style.error}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 56 56"
                >
                  <circle
                    cx="28.0006"
                    cy="27.9999"
                    r="22.4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.0003 4.66669C15.1137 4.66669 4.66699 15.1134 4.66699 28C4.66699 40.8867 15.1137 51.3334 28.0003 51.3334C40.887
          51.3334 51.3337 40.8867 51.3337 28C51.3337 15.1134 40.887 4.66669 28.0003 4.66669ZM39.667 17.7788L38.2215 16.3334L28.0003 26.5545L17.7792
          16.3334L16.3337 17.7788L26.5548 28L16.3337 38.2212L17.7792 39.6667L28.0003 29.4455L38.2215 39.6667L39.667 38.2212L29.4458 28L39.667 17.7788Z"
                  />
                </svg>
              </div>
            )
          }
        </div>
        <span className={style.title}>{localeStatus}</span>
      </div>
    )
  }

  finalRedirect = () => {
    const { store } = this.props
    const { $state } = store
    store.$actions.redirectWithTransaction($state.transaction.redirectUrl, $state.transaction.id, this.state.env)
  }

  validateRetry = (transaction) => {
    return (transaction?.canRetry && ([null, undefined].includes(transaction.paymentMethod?.extra?.parentTransactionId) && [null, undefined].includes(transaction.paymentMethod?.extra?.childTransactionId)) && !this.state.countdownFinished)
  }


  retryRedirect = () => {
    const { store } = this.props
    const { $state } = store
    const { transaction } = $state
    const checkoutUrl = String(process.env.CHECKOUT_URL)
    
    let redirectUrl = `${checkoutUrl}/retry_transaction/${transaction.id}?env=${this.state.env}`

    if (!transaction?.paymentLinkId && transaction?.signatureIntegrity) {
      redirectUrl += `&signature=${transaction?.signatureIntegrity}`
    }
    window.location.href = redirectUrl;
  }

  render(props) {
    const { store } = this.props
    const { $state } = store

    if (($state.loaders && $state.loaders.checkingTransaction) || this.state.error) {
      return this.renderLoading()
    } else if (this.state.loaded && $state.transaction) {
      return this.renderResult()
    } else if ($state.loaders.redirecting) {
      return (
        <div class={style.redirecting}>Estás siendo redirigido a la página del comercio.</div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  renderRetryMessage = () => {
    return (
      <div>
        <div className={style.retryInformationTitle}>
          <img alt='iconRetryTitle' src={retryIconTitle} />
          <h2>Intenta realizar el pago nuevamente</h2>
        </div>
        <div className={style.retryInformation}>
          <p>
            Parece que ha habido un problema al procesar tu pago.
            <br />
            <strong>Te recomendamos intentar realizar el pago nuevamente para completar tu compra</strong>
          </p>
        </div>
      </div>
    )
  }
  enabledBodyRetry = () => {
    if (this.bodyRetryRef) {
      const panel = this.bodyRetryRef;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
      this.setState((prevState) => ({
        isBodyRetryEnabled: !prevState.isBodyRetryEnabled,
      }));
    }
  }

  renderTransactionInfo = (transaction, isTransactionPCO, statusClass) => {
    const transactionInfoProps = [
      { key: 'id', label: 'Transacción #' },
    ]

    transaction.paymentMethod?.extra?.externalIdentifier &&
      transaction.paymentMethodType === "DAVIPLATA" &&
      transactionInfoProps.push({ key: 'externalIdentifier', label: 'Número de aprobación' })

    transactionInfoProps.push({ key: 'reference', label: 'Referencia' })

    const taxesTypesProps = {
      VAT: 'IVA',
      CONSUMPTION: 'Impuesto al consumo'
    }

    const titleTable = isTransactionPCO ? 'Información de la transacción con Puntos Colombia' : 'Información de la transacción'
    if (this.validateRetry(transaction)) {
      transactionInfoProps.push({key: 'statusMessage', label: 'Mensaje del operador'})
      return (
        <div className={`${style.infoTransactionRetry} ${(!this.validateRetry(transaction) ? style.orderThree : style.orderFour)}`}>
          <div className={style.paidWith}>Pago efectuado con</div>
          <img alt={transaction.paymentMethodType} src={paymentMethodsLogo[`${transaction.paymentMethodType}`].logo} className={style[paymentMethodsLogo[`${transaction.paymentMethodType}`].style]} />
          <table className={style.transactionInfo}>
            <thead onClick={this.enabledBodyRetry}>
              <tr>
                <td className={`${style.canRetryTitle} ${this.state.isBodyRetryEnabled ? style.active : ''}`} colSpan="2">Comprobante de pago</td>
              </tr>
            </thead>
          <div ref={(ref) => (this.bodyRetryRef = ref)} className={style.bodyRetry}> 
          <tbody>
            {transactionInfoProps.map(({ key, label }) => (
              transaction[key] ? (
              <tr key={key}>
                {
                  <td className={style.canRetry}><span className={style.property}>{label} </span>{transaction[key]}</td>
                }
              </tr>
            ) : key === "externalIdentifier" && (
              <tr key={key}>
                {
                  <td className={style.canRetry}><span className={style.property}>{label} </span>{transaction.paymentMethod?.extra?.externalIdentifier}</td>
                }
              </tr>
            )
            ))}
            { this.renderFieldsTransaction(transaction, pseStatusDict[transaction.status])}
          </tbody>
          </div>
        </table>
        </div>
      )
    }

    return (
      <table className={style.transactionInfo}>
        <tbody>
          <tr>
            <td className={`${style.subSection} ${style[`${statusClass}`]}`} colSpan="2">{titleTable}</td>
          </tr>
          {transactionInfoProps.map(({ key, label }) => (
            <tr key={key}>
              <td className={style.property}>{label}</td>
              <td>{key === "externalIdentifier" ? transaction.paymentMethod?.extra?.externalIdentifier : transaction[key]}</td>
            </tr>
          ))}
          {transaction.taxes && transaction.taxes.length > 0 && (
            <tr>
              <td className={style.subSection} colSpan="2">Información de impuestos</td>
            </tr>
          )}
          {transaction.taxes && transaction.taxes.map((tax) => (
            [
              <tr>
                <td className={style.property}>Tipo</td>
                <td>{taxesTypesProps[tax.type]}</td>
              </tr>,
              <tr>
                <td className={style.property}>Monto</td>
                <td>{formatPrice(tax.amountInCents)}</td>
              </tr>
            ]
          ))}
        </tbody>
      </table>
    )
  }

  hotjarRun = (validateRetry, countdownFinished) =>{
    if (validateRetry && countdownFinished){
      hotjarDispatch({type: 'event', action: 'checkout_retry'})
    }
  }

  renderFieldsTransaction = (transaction, extraValue = null) => {
    if (transaction?.paymentMethodType === 'PSE') {
      return (
        <div>
          <tr>
            <td className={style.property}>Estado de la transacción:</td>
            <td>{extraValue}</td>
          </tr>
          <tr>
            <td className={style.property}>Descripción del pago:</td>
            <td>{transaction?.paymentMethod?.paymentDescription}</td>
          </tr>
          {transaction?.paymentMethod?.extra.financialInstitutionName && (
            <tr>
              <td className={style.property}>Banco:</td>
              <td>
                {transaction?.paymentMethod?.extra?.financialInstitutionName}
              </td>
            </tr>
          )}
          <tr>
            <td className={style.property}>Pago efectuado a:</td>
            <td>
              {transaction?.merchant?.name} - (
              {transaction?.merchant?.legalName}{" "}
              {transaction?.merchant?.legalIdType}{" "}
              {transaction?.merchant?.legalId})
            </td>
          </tr>
          <tr>
            <td className={style.property}>Fecha de la transaccción:</td>
            <td>{transaction?.paymentMethod?.extra.bankProcessingDate}</td>
          </tr>
          <tr>
            <td className={style.property}>
              Código Único de Seguimiento (CUS):
            </td>
            <td>{transaction?.paymentMethod?.extra.traceabilityCode}</td>
          </tr>
          <tr>
            <td className={style.property}>Ticket ID:</td>
            <td>{transaction?.paymentMethod?.extra?.ticketId}</td>
          </tr>
        </div>
      );
    } else if (!['BANCOLOMBIA_BNPL', 'DAVIPLATA', 'SU_PLUS'].includes(transaction?.paymentMethodType)) {
      return (
        <div>
          <tr>
            <td className={style.property}>Pago efectuado a:</td>
            <td>{ transaction?.merchant?.name } - ({ transaction?.merchant?.legalName } { transaction?.merchant?.legalIdType } { transaction?.merchant?.legalId })</td>
          </tr>
          <tr>
            <td className={style.property}>Descripción del pago:</td>
            <td>{ transaction?.paymentMethod?.paymentDescription }</td>
          </tr>
          <tr>
            <td className={style.property}>Tipo de pagador:</td>
            <td>
              { transaction?.paymentMethod?.userType === 'PERSON' && 'Persona Natural' }
              { transaction?.paymentMethod?.userType === 'COMPANY' && 'Persona Jurídica' }
            </td>
          </tr>
          <tr>
            <td className={style.property}>Identificador de la intención de pago:</td>
            <td>{ transaction?.paymentMethod?.extra?.externalIdentifier }</td>
          </tr>
        </div>
      )
    } else {
      return (
        <div>
          <tr>
            <td className={style.property}>Pago efectuado a:</td>
            <td>{ transaction?.merchant?.name } - ({ transaction?.merchant?.legalName } { transaction?.merchant?.legalIdType } { transaction?.merchant?.legalId })</td>
          </tr>
          <tr>
            <td className={style.property}>Estado de la transacción:</td>
            <td>{pseStatusDict[transaction.status]}</td>
          </tr>
          <tr>
            <td className={style.property}>Tipo de documento pagador:</td>
            <td>{Documents[transaction?.paymentMethod?.userLegalIdType]}</td>
          </tr>
          <tr>
            <td className={style.property}>Documento pagador:</td>
            <td>{transaction?.paymentMethod?.userLegalId}</td>
          </tr>
        </div>
      )
    }
  }

  renderResult = () => {
    const { store } = this.props
    const { $state } = store

    const { mode } = $state
    const { transaction } = $state
    let { merchant, paymentMethod, paymentMethodType, currency, amountInCents, reference, status, redirectUrl, taxes: transactionTaxes, parentTransaction  } = transaction
    const formattedCurrency = currency && formatCurrency(currency)
    const formattedAmount = amountInCents && formatPrice(amountInCents)

    const pseStatus = pseStatusDict[status]
    const statusClass = status.toLowerCase()

    let name = null
    let description = null
    let imageUrl = null
    let taxes = transactionTaxes

    //The accumulated points amount is calculated using the rule of three, where for every $900, 1 point is accumulated
    let accumulatedPointsAmount = 0
    const PCOL_REFERENCE_CUMULATIVE_POINTS = 900
    if (paymentMethodType === 'PSE' && (parentTransaction?.paymentMethod?.extra?.remainingAmountInCents)){
      accumulatedPointsAmount = parseInt((1 * (parentTransaction.paymentMethod.extra.remainingAmountInCents / 100)) / PCOL_REFERENCE_CUMULATIVE_POINTS)
    }
    
    this.hotjarRun(
      this.validateRetry(transaction),
      !this.state.countdownFinished
    );

    // TODO - Use real ones!
    return (
      <div class={style.confirmationWrapper}>
        {merchant && (
          <div className={style.merchantSection}>
            <Header store={store} />
            <div className={style.merchantContainer}>
              {merchant && transaction && <PricePanel store={store} />}
            </div>
          </div>)
        }
        <div className={style.contentSection}>
          <div className={style.contentContainer}>
            {
              !this.validateRetry(transaction) && this.renderInfoCurrentView(transaction)
            }

            {
              status === 'ERROR' && status == 'DECLINED' &&
                (parentTransaction && parentTransaction.paymentMethod.extra && parentTransaction.paymentMethod.extra.pointsRedeemed) && (
                <span>
                  Se te reestableceran tus {parentTransaction.paymentMethod.extra.pointsRedeemed || ''} Puntos Colombia en las próximas horas
                </span>
              )
            }

            { status === 'PENDING' &&
              <div>
                <div class={style.iconWrapper}>
                  <div class={`${style.icon} ${style.pending}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      viewBox="0 0 512 512"
                    >
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z" />
                    </svg>
                  </div>
                </div>
                <div class={style.transactionSummary}>
                  {(paymentMethodType !== 'PSE' && paymentMethodType !== 'BANCOLOMBIA_BNPL') && (
                    <div>
                      <strong>Transacción en verificación</strong>
                      <p>Tu transacción está tomando más tiempo de lo normal para ser verificada. Pronto recibirás un email con el resultado final de la misma.&nbsp;
                        {
                          parentTransaction && 'Si la transacción finaliza de manera exitosa, se notificará al comercio, en caso de que resulte fallida se te reestableceran tus Puntos Colombia en las próximas horas. '
                        }
                      </p>
                  </div>
                  )}
                  { paymentMethodType === 'PSE' && (
                    <div>
                      <strong>Transacción pendiente</strong>
                      <p>En este momento tu transacción (ref. <strong>{reference}</strong>) presenta un proceso de pago cuya verificación&nbsp;
                        se encuentra <strong>PENDIENTE</strong> de recibir confirmación por parte de la entidad financiera.&nbsp;
                        Por favor espera unos minutos y vuelve a consultar más tarde para verificar si tu pago fue confirmado de forma exitosa.&nbsp;
                        {
                          parentTransaction && 'Si la transacción finaliza de manera exitosa, se notificará al comercio, en caso de que resulte fallida se te reestableceran tus Puntos Colombia en las próximas horas. '
                        }
                        Si deseas mayor información sobre el estado actual de tu operación puedes comunicarte con <strong>{merchant.name}</strong> escribiendo a&nbsp;
                        <strong><a href={`mailto:${merchant.email}`}>{merchant.email}</a></strong> y preguntar por el estado de la transacción <strong>#{paymentMethod.extra.traceabilityCode}</strong></p>
                    </div>
                  )}
                  {paymentMethodType === 'BANCOLOMBIA_BNPL' && (
                    <div className={style.summary}>
                      <strong>Entendemos que esperar puede resultar incómodo, pero necesitamos este tiempo para procesar tu pago y completar la compra.</strong>
                      <p>
                        <ul>
                          <li>Si después de unos minutos sigues viendo este mensaje, te recomendamos recargar la página.</li>
                          <li>Además, recibirás información sobre el estado final de la transacción en tu correo registrado.</li>
                        </ul>
                      </p>
                    </div>
                  )}
                  <br/>
                  <div className={style.referencePanel} hidden={paymentMethodType === 'BANCOLOMBIA_BNPL'}>
                    <div>Referencia: <strong>{reference}</strong></div>
                  </div>
                </div>
              </div>
            }
            {
              status === 'APPROVED' && (parentTransaction && parentTransaction.paymentMethod.extra) &&
              <PCOLSummaryBox redeemedPoints={parentTransaction.paymentMethod.extra.pointsRedeemed || 0}
                moneyAmount={parentTransaction.paymentMethod.extra.remainingAmountInCents || 0}
                accumulatedPoints={accumulatedPointsAmount} />
            }

            {
              parentTransaction && this.renderTransactionInfo(parentTransaction, true, statusClass)
            }

            {
              this.validateRetry(transaction) && (
                <div className={style.orderOne}>
                  {this.renderRetryMessage()}
                  { this.validateRetry(transaction) &&  <RetryTime store={store} onCountdownFinished={() => this.handleCountdownFinished()}/>}
                </div>
              )
            }

            {this.renderTransactionInfo(transaction, false, statusClass)}

            { paymentMethodType === 'BANCOLOMBIA_TRANSFER' && paymentMethod?.extra && !this.validateRetry(transaction) && (
              <div class={style.asyncPaymentMethodWrapper}>
                <div class={style.paidWith}>Pago efectuado con</div>
                <img alt={paymentMethodType} src={logoBANCOLOMBIA_TRANSFER} class={style.theBancolombiaLogo} />
                {/*<div class={style.title}>Transferencia con Botón Bancolombia</div>*/}
                <table className={style.transactionInfo} cellpadding="5">
                  <tbody>
                  <tr>
                    <td className={`${style.subSection} ${style[`${statusClass}`]}`} colSpan="2">Comprobante de pago Botón Bancolombia</td>
                  </tr>
                  { this.renderFieldsTransaction(transaction,pseStatus)}
                  </tbody>
                </table>
              </div>
            )}

            { paymentMethodType === 'PSE' && paymentMethod?.extra && !this.validateRetry(transaction) && (
              <div class={style.asyncPaymentMethodWrapper}>
                <div class={style.paidWith}>Pago efectuado con</div>
                <img src={logoPSE} class={style.theLogo} alt="Logo PSE" />
                {/*<div class={style.title}>Comprobante de pago PSE</div>*/}
                <table className={style.transactionInfo} cellpadding="5">
                  <tbody>
                  <tr>
                    <td className={`${style.subSection} ${style[`${statusClass}`]}`} colSpan="2">Comprobante de pago PSE</td>
                  </tr>
                  { this.renderFieldsTransaction(transaction,pseStatus)}
                  </tbody>
                </table>
              </div>
            )}
            { paymentMethodType === 'BANCOLOMBIA_BNPL' && paymentMethod?.extra && !this.validateRetry(transaction) && (
              <BNPLSummary transaction={transaction} />
            )}
            { paymentMethodType === 'DAVIPLATA' && paymentMethod?.extra && !this.validateRetry(transaction) && (
              <DaviplataSummary transaction={transaction} />
            )}
            { paymentMethodType === 'SU_PLUS' && paymentMethod?.extra && !this.validateRetry(transaction) && (
              <SuPlusPaySummary transaction={transaction} />
            )}
            <div className={`${(this.validateRetry(transaction) ? style.orderOne : style.orderThree)}`}>
              <div class={`${style.redirectButtons} ${style.hideForPrinting}`}>
                { this.validateRetry(transaction) && <button className={`button-primary ${style.hideForPrinting} ${style.retryButton}`} onClick={this.retryRedirect}>Intenta pagar nuevamente</button> }
                { redirectUrl && <button className={`button-primary ${style.hideForPrinting}`} onClick={this.finalRedirect}>Regresar al comercio</button> }
              </div>
              <a className={`${style.print} ${style.hideForPrinting}`} onClick={this.print} onKeyDown={this.print}>Imprimir comprobante</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Confirmation.propTypes = {
  store: PropTypes.shape({
    $state: PropTypes.shape({
      transaction: PropTypes.shape({
        paymentMethod: PropTypes.shape({
          extra: PropTypes.shape({
            remainingAmountInCents: PropTypes.number,
            traceabilityCode: PropTypes.string, // Add validation for missing properties
            externalIdentifier: PropTypes.string,
            financialInstitutionName: PropTypes.string,
            bankProcessingDate: PropTypes.string, // Assuming it's a string
            ticketId: PropTypes.string,
          }),
        }),
        parentTransaction: PropTypes.shape({ // Already included from previous response
          paymentMethod: PropTypes.shape({
            extra: PropTypes.object,
          }),
        }),
        paymentLinkId: PropTypes.string,
        signatureIntegrity: PropTypes.string,
      }),
    }),
    $operations: PropTypes.shape({
      getRetryTransactionConfig: PropTypes.func,
    }),
  }),
};

export default Confirmation
