import 'core-js/features/url';
import 'core-js/features/url-search-params';
import LS from '../helpers/LS'

export default {

  initApp ( store, ls = LS ) {
    const mode = ls.getString('mode')
    if ( mode ) store.$actions.setMode(mode)

    const merchant = ls.get('merchant')
    if ( merchant ) store.$actions.setMerchant(merchant)

    const transaction = ls.get('transaction')
    if ( transaction ) store.$actions.setTransaction(transaction)

    const method = ls.get('method')
    if ( method ) store.$actions.setMethod(method)

    const paymentLink = ls.get('paymentLink')
    if ( paymentLink ) store.$actions.setPaymentLink(paymentLink)

    // const publicKey = ls.get('publicKey')
    // if ( publicKey ) store.$actions.setPublicKey(publicKey)
  },
  
  setLoader ( store, name, bool ) {
    store.$mutations.SET_LOADER(name, bool)
  },

  setMode ( store, mode ) {
    localStorage.setItem('mode', mode)
    store.$mutations.SET_MODE(mode)
  },

  setEnv ( store, env ) {
    localStorage.setItem('env', env)
    store.$mutations.SET_ENV(env)
  },  

  setMerchant ( store, merchant ) {
    if ( merchant === null ) {
      localStorage.removeItem('merchant')
    } else {
      localStorage.setItem('merchant', JSON.stringify(merchant))
    }
    store.$mutations.SET_MERCHANT(merchant)
  },

  setTransaction ( store, transaction ) {
    if ( transaction === null ) {
      localStorage.removeItem('transaction')
    } else {
      localStorage.setItem('transaction', JSON.stringify(transaction))
    }
    store.$mutations.SET_TRANSACTION(transaction)
  },

  setMethod ( store, method ) {
    if ( method === null ) {
      localStorage.removeItem('method')
    } else {
      localStorage.setItem('method', JSON.stringify(method))
    }
    store.$mutations.SET_METHOD(method)
  },

  setCreditCardInfo ( store, creditCardInfo ) {
    store.$mutations.SET_CREDIT_CARD_INFO(creditCardInfo)
  },

  setPaymentLink ( store, linkData ) {
    if ( linkData === null ) {
      localStorage.removeItem('paymentLink')
    } else {
      localStorage.setItem('paymentLink', JSON.stringify(linkData))
    }
    store.$mutations.SET_PAYMENT_LINK(linkData)
  },

  setPublicKey ( store, publicKey ) {
    if ( publicKey === null ) {
      localStorage.removeItem('publicKey')
    } else {
      localStorage.setItem('publicKey', publicKey)
    }
    store.$mutations.SET_PUBLIC_KEY(publicKey)
  },

  isSandbox ( store ) {
    const { $state } = store
    return typeof $state.env === 'string' && $state.env.indexOf('test') >= 0
  },

  resetPayment ( store ) {
    store.$actions.setMethod(null)
    store.$actions.setPaymentLink(null)
    store.$actions.setPublicKey(null)
    store.$actions.setTransaction(null)
    store.$actions.setMerchant(null)
  },

  redirectWithTransaction ( store, redirectUrl, transactionId, env ) {
    const url = new URL(redirectUrl)
    url.searchParams.append('id', transactionId)
    url.searchParams.append('env', env)  // Gets the prefix, from i.e.: pub_test_ajk929iai2sji9sjk
    const firstParam = url.search.substring(1).split('&')[0]
    let finalUrl = url.href

    if (redirectUrl.split('/#/').length === 2 && url.href.indexOf('#/') > url.href.indexOf(firstParam)) {
      finalUrl = `${url.href.substr(0, redirectUrl.indexOf('/#/'))}/#/${redirectUrl.substr(redirectUrl.indexOf('/#/') + 3)}${url.search}`
    }
    window.location.assign(finalUrl)
  },

}