import { camelizeKeys, decamelizeKeys } from 'humps'
import LS from './helpers/LS'

const url = ( token, ls = LS ) => {
  const BASEURL = String(process.env.WAYBOX_API_URL)
  const BASEURLSANDBOX = String(process.env.WAYBOX_API_SANDBOX_URL)
  const env = ls.getString('env')

  if ( env === 'devint' || env === 'stagint' || env === 'prod' ||  env === 'VPOS' ) {
    return BASEURL
  } else if ( env === 'devtest' || env === 'stagtest' || env === 'test') {
    return BASEURLSANDBOX
  } else {
    return BASEURL
  }
}

const token = ( ls = LS ) => ls.getString('publicKey')

const parseResponse = response => {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      response.text().then( (text) => text ? resolve(camelizeKeys(JSON.parse(text))) : resolve({}) )
    } else {
      const status = response.status
      const statusText = response.statusText

      if (status === 400 || status === 422) {
        response.json().then(json => {
          reject({ status, statusText, json: camelizeKeys(json) })
        })
      } else {
        reject({ status, statusText })
      }
    }
  })
}

const get = ( path, requireToken = false ) => {
  let headers = {
    'Content-Type': 'application/json',
  }

  if (requireToken) {
    const storedToken = token()

    if ( typeof storedToken === 'string' ) {
      headers['Authorization'] = 'Bearer ' + storedToken
    }
  }

  return window.fetch(url() + path, {
    method: 'GET',
    headers: headers,
  }).then(parseResponse)
}

const post = ( path, data ) => {
  const storedToken = token()
  let headers = {
    'Content-Type': 'application/json',
  }
  
  // if ( typeof storedToken === 'string' ) {
  //   headers['Authorization'] = 'Bearer ' + storedToken
  // }

  return window.fetch(url() + path, {
    method: 'POST',
    body: JSON.stringify(decamelizeKeys(data)),
    headers: headers,
  }).then(parseResponse)
}

export default { get, post }
