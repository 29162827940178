export default {
  SET_LOADER ( state, name, bool ) { state.loaders[name] = bool },
  SET_MODE ( state, val ) { state.mode = val },
  SET_ENV ( state, val ) { state.env = val },
  SET_MERCHANT ( state, val ) { state.merchant = val },
  SET_TRANSACTION ( state, val ) { state.transaction = val },
  SET_METHOD ( state, val ) { state.method = val },
  SET_CREDIT_CARD_INFO ( state, val ) { state.creditCardInfo = val },
  SET_PAYMENT_METHOD ( state, val ) { state.paymentMethod = val },
  SET_PUBLIC_KEY ( state, val ) { state.paymentMethod = val },
  SET_PAYMENT_LINK ( state, obj ) { state.paymentLink = obj },
}