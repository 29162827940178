import style from "./Hello.sass";

class Hello {
  render() {
    return (
      <div className={style.container}>
        <h1>Página no disponible</h1>

        <p>Te invitamos a que sigas navegando por nuestra página web y si eres desarrollador revisa nuestra documentación.</p>
        <div className={style.containerButtons}>
          <a
            href={ String(process.env.WEBSITE_URL) }
            className={`button-primary`}
            target="_blank"
            >Ir al sitio web de { String(process.env.PRODUCT_NAME) }</a>
          <a
            href={ String(process.env.DOCS_URL) }
            className={`button-secondary`}
            target="_blank"
            >Explora la documentación</a>
        </div>
      </div>
    )
  }
}

export default Hello