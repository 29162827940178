import Actions from './Actions'
import Mutations from './Mutations'
import LS from './helpers/LS'
import Operations from './Operations'
import WayboxAPI from './WayboxAPI'

export default {

  create ( state, app, acs = Actions, mts = Mutations, ops = Operations, api = WayboxAPI, ls = LS ) {
    let store = {
      $state: state,
    }
    
    let actions = {}
    acs.forEach( ( xs ) => {
      let keys = Object.keys(xs).forEach( ( x ) => {
        if ( actions[x] === undefined ) {
          actions[x] = function ( ) {
            let args = []
            // Array.prototype.slice isn't recommended because of optimization issues:
            // https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Funciones/arguments#Descripci.C3.B3n
            // Iteration used instead
            for ( let i = 0; i < arguments.length; i++ ) {
              args[i] = arguments[i]
            }
            // return xs[x].apply(this, [store].concat(args))
            return xs[x].apply(store, [store].concat(args))
          }
        } else {
          throw new Error(`Action «${x}» has a duplicate.`)
        }
      })
    })
    store.$actions = actions

    let mutations = {}
    Object.keys(mts).forEach( ( x ) => {
      mutations[x] = function ( ) {
        let args = []
        // Array.prototype.slice isn't recommended because of optimization issues:
        // https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Funciones/arguments#Descripci.C3.B3n
        // Iteration used instead
        for ( let i = 0; i < arguments.length; i++ ) {
          args[i] = arguments[i]
        }

        let isDebug = ls.getString('_debug') === '*'
        // TODO: Should be set using Webpack or something similar
        // if ( process.env.ENV === 'development' && isDebug ) {
        if ( isDebug ) {
          console.warn(`Mutation «${x}» called with arguments:\n`, args)
        }

        mts[x].apply(store, [store.$state].concat(args))
        // Needed to globally update store across App
        app.setState({ store })
      }
    })
    store.$mutations = mutations

    let operations = {}
    Object.keys(ops).forEach( ( x ) => {
      operations[x] = function ( ) {
        let args = []
        for ( let i = 0; i < arguments.length; i++ ) {
          args[i] = arguments[i]
        }
        return ops[x].apply(store, [api].concat(args))
      }
    })
    store.$operations = operations
    
    return store
  },

}